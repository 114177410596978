// bind to window
window.megamenu = function (authGuest, token) {

    const
    ico_map_pin = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clip-rule="evenodd"></path></svg>`,
    ico_phone = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"> <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z"></path> <path fill-rule="evenodd" d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z" clip-rule="evenodd"></path> </svg>`,
    ico_user = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" /></svg>`,
    ico_careers = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z" clip-rule="evenodd" /><path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" /></svg>`,
    ico_contacts = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" /><path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" /></svg>`,
    ico_news = `<svg class="icon w-4 h-4 -mt-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fill-rule="evenodd" d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 0 0 3 3h15a3 3 0 0 1-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125ZM12 9.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H12Zm-.75-2.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75ZM6 12.75a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5H6Zm-.75 3.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM6 6.75a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 9 6.75H6Z" clip-rule="evenodd" /><path d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 0 1-3 0V6.75Z" /></svg>`,
    hr = `<hr class="mt-2 mb-4 border-navy-2"/>`,
    hr_mobile = `<hr class="mt-2 mb-4 border-navy-2 md:mt-0 md:mb-0 md:border-0"/>`,
    imageSettings = `/image?t=${token}&h=550&q=35&&from=center&from_vertical=middle&crop_w=800&crop_h=548&test=true&f=`,
    navItem = (target, hoverImage, displayName, imageCount='single') => `
    <li class="nav--item">
    <a href="${target}" title="Visit ${displayName}" tabindex="0"
        data-hover-image="${imageSettings + hoverImage}" data-image-count="${imageCount}">${displayName}</a>
    </li>`,
    navItem_icon = (target, hoverImage, displayName, icon, imageCount='single') => `
    <div class="flex items-center gap-1 uppercase text-body">
        <div class="w-4 flex-shrink-0">${icon}</div>
        <li class="nav--item">
            <a href="${target}" title="Visit ${displayName}" tabindex="0" class=" "
                data-hover-image="${imageSettings + hoverImage}" data-image-count="${imageCount}">${displayName}</a>
        </li>
    </div>
    `,
    navList = (title, items) => `
    <h4 class="mb-2 uppercase button-text-medium text-body">${title}</h4>
    <ul class="mb-0">
    ${items}
    </ul>`,
    navList_Sunbed_Tanning = navList(
        'Sunbed Tanning', `
        ${navItem('/sunbed-tanning', 'nav___Sunbed_Tanning_Home.png', 'Sunbed Tanning Home', 'double')}
        ${navItem('/sunbed-tanning/sunbed-tips', 'nav___Sunbed_Tanning_Tips.png', 'Sunbed Tanning Tips', 'double')}
        ${navItem('/sunbed-tanning/our-sunbeds', 'nav___Our_Tanning_Beds.png', 'Our Tanning Beds', 'double')}
    `),
    navList_Wellness_Within = navList(
        'Wellness', `
        ${navItem('/wellness', 'nav___Wellness_Within_Supplements.png', 'Wellness Home')}
        ${navItem('/wellness/supplements', 'nav___Wellness_Within_Supplements.png', 'Supplements')}
    `),
    navList_Spray_Tanning = navList(
        'Spray Tanning', `
        ${navItem('/sunless-spray-tanning', 'nav___Spray_Tanning_Home_1.png', 'Spray Tanning Home', 'double')}
        ${navItem('/sunless-spray-tanning/spray-tan-tips', 'nav___Spray_Tan_Tips.png', 'Spray Tan Tips', 'double')}
        ${navItem('/sunless-spray-tanning/spray-tan-systems', 'nav___Solutions_and_Spray_Booths.png', 'Solutions and Spray Booths', 'double')}
        ${navItem('/sunless-spray-tanning/spray-tan-systems/try-your-tan', 'nav___Try_Your_Tan.png', 'Try Your Tan', 'double')}
    `),
    navList_Pricing_and_Rewards = navList(
        'Pricing & Rewards', `
        ${navItem('/pricing-rewards', 'nav___Pricing_and_Rewards.png', 'Pricing & Rewards')}
        ${navItem('/pricing-rewards/reserve-your-membership', 'nav___Reserve_Your_Membership.png', 'Reserve Your Membership', 'double')}
        ${navItem('/pricing-rewards/refer-a-friend', 'nav___Contact_Us.png', 'Refer-a-Friend', 'double')}
    `),
    navList_Skin_Care_Products = navList(
        'Skin Care Products', `
        ${navItem('/skin-care-products', 'nav___Shop_All_Nav_1.png', 'Shop All')}
        ${navItem('/skin-care-products?products=bronzers#pbtsc', 'nav___Bronzers_1.png', 'Bronzers', 'double')}
        ${navItem('/skin-care-products?products=intensifiers#pbtsc', 'nav___Intensifiers.png', 'Intensifiers', 'double')}
        ${navItem('/skin-care-products?products=face#pbtsc', 'nav___Face.png', 'Face', 'double')}
        ${navItem('/skin-care-products?products=extenders#pbtsc', 'nav___Extender_Nav.png', 'Extenders', 'double')}
        ${navItem('/skin-care-products?products=bath-body#pbtsc', 'nav___Bath_and_Body_Nav_1.png', 'Bath & Body', 'double')}
        ${navItem('/skin-care-products?products=self-tanners#pbtsc', 'nav___Self_Tanners.png', 'Self Tanners', 'double')}
        ${navItem('/skin-care-products?products=primers#pbtsc', 'nav___primers_3.png', 'Primers', 'double')}
        ${navItem('/skin-care-products?products=accessories#pbtsc', 'nav___Accessories.png', 'Accessories')}
    `),
    navList_iconLinks = `
        ${navItem_icon('/locations', 'nav___Find_Location_Nav.png', 'Find&nbsp;Your&nbsp;Location', ico_map_pin)}
        ${navItem_icon('/contact-us', 'nav___Contact_Us.png', 'Contact&nbsp;Us', ico_phone, 'double')}`,
        navList_iconLinksExpanded = `
        ${navList_iconLinks}
        ${navItem_icon('/contact-us/corporate-contacts', 'nav___Corporate_Contact_Nav_Image.png', 'Corporate&nbsp;Contacts', ico_contacts)}
        ${navItem_icon('/making-headlines', 'nav___Find_Location_Nav.png', 'Making&nbsp;Headlines', ico_news)}
        ${navItem_icon('/careers', 'nav___Careers_Nav.png', 'Careers', ico_careers, 'double')}
        ${navItem_icon('/my-pbt', 'nav___My_PBT_Nav.png', 'MyPBT™', ico_user, 'double')}`,
    navList_myPbt = `
        ${navItem('/my-pbt/account/logout', 'nav___Accessories.png', 'Sign Out')}`,
        megaMenu_FullNav = (id) => `
            <nav id="mega-menu-${id}" class="py-8 mx-4 border-t border-gray-transparent-4 mega-menu"
                aria-label="Secondary Nav">
                <div class="md:grid md:grid-cols-12 max-w-9xl m-auto gap-8">
                    <div class="md:flex gap-8 md:col-span-12 lg:col-span-6 ">
                        <div class="md:pb-8">
                            ${navList_Sunbed_Tanning + hr + navList_Spray_Tanning}
                        </div>
                        <div class="md:pb-8">
                            ${hr_mobile + navList_Skin_Care_Products + hr + navList_Wellness_Within + hr_mobile }
                        </div>
                        <div class="md:pb-8">
                            ${ navList_Pricing_and_Rewards + hr  + navList_iconLinksExpanded + hr + (authGuest === true ? navList_myPbt : '')}
                        </div>
                    </div>
                    <div class="md:hidden lg:block lg:col-span-6">

                        <figure class="relative double" id="picture-frame">

                            <picture class="absolute inset-0 object-cover opacity-0 block" id="picture-single">
                                <div class="split-full" style="
                                    overflow: hidden;
                                    border-radius: 16px;">
                                    <img src="${imageSettings + 'nav___Bronzers.png'}"
                                        class="hidden rounded-lg md:block absolute inset-0 object-cover"
                                        width="600"
                                        height="400"
                                        style="
                                            height: auto;
                                            max-width: 100%;
                                        ">
                                </div>
                            </picture>

                            <picture class="relative justify-between z-10 opacity-0 flex" id="picture-double">
                                <div class="split-l rounded-lg">
                                    <img src="${imageSettings + 'nav___Bronzers.png'}"
                                        class="hidden rounded-lg md:block"
                                        width="600"
                                        height="400">
                                </div>
                                <div class="split-r rounded-lg">
                                    <img src="${imageSettings + 'nav___Bronzers.png'}"
                                        class="hidden rounded-lg md:block"
                                        width="600"
                                        height="400">
                                </div>
                            </picture>

                        </figure>
                    </div>
                </div>
            </nav>`

        // Write to placeholder:
        document.querySelectorAll('.placeholder-megamenu-template').forEach((item) => {
        item.innerHTML =
            // megaMenu_Template('sunbed-tanning') +
            // megaMenu_Template('spray-tanning') +
            // megaMenu_Template('wellness') +
            megaMenu_FullNav('full-nav')
        })

    // MEGA MENU
    // Set variables
    const navItems = document.querySelectorAll('.mega-menu .nav--item a'),
    navWrapper = document.querySelector('#site-header'),
    openMenuLinks = ['sunbed-tanning', 'spray-tanning', 'wellness']
    // Define functions
    function removeAllMenus(items) {
    items.forEach((item) => {
        navWrapper.classList.remove('expand-' + item, 'expand-full-nav')
        var currentLink = document.querySelector('.openmenu-' + item + ' a')
        currentLink.classList.remove('active')
    })
    }
    function backdropToggle(state) {
    if (state === 'on') {
        removeAllMenus(openMenuLinks)
        document.querySelector('#backdrop').classList.remove('hidden')
        setTimeout(() => {
            document.querySelector('#backdrop').classList.add('animate-fadeIn')
        }, 100)
        document.querySelector('body').classList.add('overflow-hidden')
    } else {
        navWrapper.classList.remove('expand-full-nav')
        document.querySelector('#backdrop').classList.remove('animate-fadeIn')
        document.querySelector('#navbarToggleBtn').classList.remove('active')
        setTimeout(() => {
            document.querySelector('#backdrop').classList.add('hidden')
        }, 100)
        document.querySelector('body').classList.remove('overflow-hidden')
    }
    }
    // swap image when hovering over nav items
    navItems.forEach((item) => {
    item.addEventListener('mouseenter', (e) => {
        if (window.innerWidth > 1024) {
            const hoverImage = e.target.getAttribute('data-hover-image'),
                hoverImageElement = document.querySelectorAll('.mega-menu picture img'),
                imageCount = e.target.getAttribute('data-image-count'),
                pictureFrame = document.querySelector('#picture-frame')
            hoverImageElement.forEach((img) => {
                pictureFrame.classList.remove('double')
                pictureFrame.classList.remove('single')
                setTimeout(() => {
                    if (imageCount === 'single') {
                        pictureFrame.classList.add('single')
                    } else if (imageCount === 'double') {
                        pictureFrame.classList.add('double')
                    }
                    if (hoverImage !== '') {
                            // apply styles to parent element
                            // radomly choose a direction
                            var directions = ['-',''],
                                direction = directions[Math.floor(Math.random() * directions.length)],
                                distance = Math.floor(Math.random() * 40) - 5

                            pictureFrame.style.transform = `translate3d(0, ${direction + distance + 'px'}, 0)`

                            // apply styles to image
                            img.style.transitionDuration = '0s'
                            img.style.opacity = '0'
                            img.style.transform = `rotate(${Math.floor(Math.random() * 10) - 5}deg) scale(1.1)`
                            // apply blur filter
                            // img.style.filter = `blur(4px)`

                            // set new image src
                            setTimeout(() => {
                                img.setAttribute('src', hoverImage)
                                // wait for image to load
                                img.addEventListener('load', () => {
                                    // apply styles to parent element
                                    pictureFrame.style.transitionDuration = '3s'
                                    pictureFrame.style.transform = `translate3d(0,0,0)`
                                    // apply styles to image
                                    img.style.transitionDuration = '1s'
                                    img.style.transform = `rotate(0deg) scale(1)`
                                    img.style.opacity = '1'
                                    // reset blur filter
                                    // img.style.filter = `blur(0px)`
                                })
                            }, 100) // wait for opacity to be 0
                            // reset pictureFrame styles
                            setTimeout(() => {
                                pictureFrame.style.transitionDuration = '0s'
                            }, 1000)

                    } else {
                        // reset to default image
                        img.setAttribute('src', `${imageSettings + 'nav___Bronzers.png'}`)
                    }
                }, 10)
            })
        } // end window.innerWidth check
    })
    })

    document.querySelector('#navbarToggleBtn').addEventListener('click', (e) => {
    if (document.querySelector('#navbarToggleBtn').classList.contains('active')) {
        backdropToggle('off')
        navWrapper.classList.remove('expand-full-nav')
        document.querySelector('#navbarToggleBtn').classList.remove('active')
    } else {
        backdropToggle('on')
        navWrapper.classList.add('expand-full-nav')
        document.querySelector('#navbarToggleBtn').classList.add('active')
    }
    })

    // remove active class from navWrapper when backdrop is clicked
    document.querySelector('#backdrop').addEventListener('click', (e) => {
    removeAllMenus(openMenuLinks)
    backdropToggle('off')
    navWrapper.classList.remove('expand-full-nav')
    })
}